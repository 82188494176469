// Our variables
$base-font-family: "league_spartanregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondary-font-family: "knewaveregular", "Comic Sans MS";
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$white-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$red-color: #FC0D1B;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import "reset",
        "base",
        "layout",
        "syntax-highlighting",
        "typography"
;

.banner {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: $red-color;
  padding: 20px 0;
  font-size: 20px;
  font-family: $secondary-font-family;
  letter-spacing: 1px;
  text-align: center;
  a {
    color: $white-color;
    text-decoration: none;
  }
}

.banner-spacer {
  height: 72px;
}

@include media-query($on-palm) {
  .banner {
    font-size: 16px;
    padding: 16px 0;
  }
  .banner-spacer {
    height: 56px;
  }
}

#info-pane {
  position: fixed;
  width: 170px;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1;

  .logo {
    margin-top: 140px;
    background-image: url("/images/logo.gif");
    background-size: cover;
    height: 170px;
    width: 170px;
  }

  h2.title {
    margin-top: -35px;
    text-align: center;
    font-size: 25px;
    a {
      color: black;
      text-decoration: none;
    }
  }

  .tagline {
    background: $white-color;
    margin-left: 25px;
    width: 342px;
    font-size: 18px;
  }

}

@include media-query($on-palm) {
  #info-pane {
     position: absolute;
  }
}

#photo-grid {
  margin-left: 200px;
}

.card .front {
  background-size: cover;
  height: 100%;
}

.grid-item {
  width: 200px;
  height: 300px;
  float: left;
  margin: 2px;
}

.footer {
  padding-top: 15px;
  padding-bottom: 15px;
  clear: both;
  font-size: 10px;

  ul {
    margin-right: 40px;
    li {
      margin-right: 10px;
      display: block;
      float: right;
      a {
        color: $text-color;
        text-decoration: underline;
      }
    }
  }
}
@include media-query($on-palm) {
  .footer {
    padding-top: 20px;
    ul {
      margin-right: 0;
      margin-left: 0;
      li {
        float: none;
        text-align: center;
      }
      margin-bottom: 50px;
    }
  }
}

#actions {
  font-family: $secondary-font-family;
  position: fixed;
  bottom: 50px;
  left: 50px;
  ul {
    margin: 0;
  }
  li {
    padding: 10px 0;
  }
  a {
    font-size: 20px;
    color: $red-color;
  }
  a:hover {
    // Want underline a little lower than standard link underline so using a
    // custom border
    border-bottom: solid $red-color 2px;
    text-decoration: none;
  }
}


@include media-query($on-palm) {
  #info-pane {
    position: static;
    width: 100%;
    padding: 0;

    .logo {
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .tagline {
      margin-top: 24px;
      width: auto;
    }
  }

  #photo-grid {
    margin: 0;
  }

  .grid-item {
    width: 100vw;
    height: 150vw;
    margin-left: 0;
    margin-right: 0;
  }

  #actions {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
}


