@font-face {
    font-family: 'league_spartanregular';
    src: url('/fonts/leaguespartan-bold-webfont.eot');
    src: url('/fonts/leaguespartan-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/leaguespartan-bold-webfont.woff2') format('woff2'),
         url('/fonts/leaguespartan-bold-webfont.woff') format('woff'),
         url('/fonts/leaguespartan-bold-webfont.ttf') format('truetype'),
         url('/fonts/leaguespartan-bold-webfont.svg#league_spartanregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'knewaveregular';
    src: url('/fonts/knewave-webfont.eot');
    src: url('/fonts/knewave-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/knewave-webfont.woff2') format('woff2'),
         url('/fonts/knewave-webfont.woff') format('woff'),
         url('/fonts/knewave-webfont.ttf') format('truetype'),
         url('/fonts/knewave-webfont.svg#knewaveregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

